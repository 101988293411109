<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-color', params: { accountId: this.$route.params.accountId } }">Colors</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ color.label }}</h1>
                <p class="text-caption text-center">Color overview</p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="color">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0 mt-8">Label</p>
                    <p class="mb-0 pb-0">
                        {{ color.label }}
                        <v-btn icon color="green" @click="editColorLabel">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>

                    <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                    <p class="mb-0 pb-0">
                        {{ color.comment }}
                        <v-btn icon color="green" @click="editColorComment">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p> -->

                    <p class="text-overline mb-0 mt-8">Color ID</p>
                    <p class="mb-0 pb-0">
                        {{ color.id }}
                    </p>

                    <!-- TODO: show the usages of the color, such as which palettes, which images, etc. -->
                    <!-- <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="affectedEmailContactList.length > 0">
                            This color is subscribed by {{ affectedEmailContactList.length }} email contacts.
                            < ! - - TODO: show the affectedEmailContactList - - >
                        </span>
                        <span v-if="affectedEmailContactList.length === 0">
                        This color is not currently applied to any email contacts.
                        </span>
                    </p> -->

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, colorId: this.$route.params.colorId } }">Access control</router-link>
                    </p> -->

                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Color Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.color, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editColorLabelDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the color label</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The color label can be technically descriptive, such as "light blue", or it can be more emotionally descriptive, like "sunrise sky".
                            You can change it at any time. The color label is PUBLIC.
                        </p>

                        <v-text-field
                            ref="colorLabelInput"
                            v-model="editableColorLabel"
                            label="Label"
                            :rules="newColorLabelRules"
                            validate-on-blur
                            color="green"
                            required
                            type="text"
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditColorLabel" :disabled="!isEditColorLabelComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editColorLabelDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editColorCommentDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the color comment</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The color comment is a place where you can add more information for your own reference about how you use the color.
                            You can change it at any time. The comment is PRIVATE. It is NOT shown to users.
                        </p>
                        <v-textarea v-model="editableColorComment" label="Color comment"></v-textarea>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditColorComment" :disabled="!isEditColorCommentComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editColorCommentDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
// import ColorList from '@/components/account-dashboard/ColorList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        // ColorList,
    },
    data: () => ({
        // affectedEmailContactList: [],

        error: null,
        account: null,
        color: null,
        invite: null,
        // edit form title dialog
        editColorLabelDialog: false,
        editColorCommentDialog: false,
        editableColorComment: null,

        editableColorLabel: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.form !== null;
        },
        isEditColorLabelComplete() {
            return typeof this.editableColorLabel === 'string' && this.editableColorLabel.trim().length > 0;
        },
        isEditColorCommentComplete() {
            return typeof this.editableColorComment === 'string' && this.editableColorComment.trim().length > 0;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadColor() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadColor: true });
                const response = await this.$client.account(this.$route.params.accountId).color.get({ id: this.$route.params.colorId });
                console.log(`loadColor: response ${JSON.stringify(response)}`);
                if (response) {
                    this.color = response;
                    // TODO: set the editable fields instead, like this.editableDisplayname = response.comment ?? '';
                    // this.color.comment ??= '';
                    // this.color.reply_to ??= '';
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load color');
                }
            } catch (err) {
                console.error('failed to load color', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadColor: false });
            }
        },
        async save(colorAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditColor: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).color.edit({ id: this.$route.params.colorId }, colorAttr);
                console.log(`saveEditColor: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit color' });
                return false;
            } catch (err) {
                console.error('failed to edit color', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit color' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditColor: false });
            }
        },
        editColorLabel() {
            this.editColorLabelDialog = true;
            this.editableColorLabel = this.color.label;
        },
        editColorComment() {
            this.editColorCommentDialog = true;
            this.editableColorComment = this.color.comment;
        },
        async saveEditColorLabel() {
            const isEdited = await this.save({ label: this.editableColorLabel });
            this.editColorLabelDialog = false;
            if (isEdited) {
                this.$set(this.color, 'label', this.editableColorLabel);
            }
        },
        async saveEditColorComment() {
            const isEdited = await this.save({ comment: this.editableColorComment });
            this.editColorCommentDialog = false;
            if (isEdited) {
                this.$set(this.color, 'comment', this.editableColorComment);
            }
        },
        async editColorStatus(newValue) {
            // NOTE: server does access control check for this, only service admin may use the function
            try {
                this.$store.commit('loading', { editColorStatus: true });
                const response = await this.$client.account(this.$route.params.accountId).colorStatus.edit({ color_id: this.$route.params.colorId }, { status: newValue });
                console.log(`color/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.color.status = newValue;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Color status updated', message: `New status: ${newValue}` });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update color status' });
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update color status' });
            } finally {
                this.$store.commit('loading', { editColorStatus: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadColor();
    },
};
</script>
